require('mmenu-js/src/mmenu.scss');
require('./styles/main.scss');

import Mmenu from 'mmenu-js';

function alignMegaMenus() {
  const headerNav = document.querySelector('header nav');
  if (headerNav) {
    const withSubmenu = headerNav.querySelectorAll('.level_1 > .submenu > a');
    withSubmenu.forEach(link => {
      link.addEventListener('mouseover', function (event) {
        const ul = link.parentElement.querySelector('.level_2');
        if (ul) {
          const navRect = headerNav.getBoundingClientRect()
          const linkRect = link.getBoundingClientRect();
          const ulRect = ul.getBoundingClientRect();
          if (linkRect.x + ulRect.width > navRect.x + navRect.width) {
            ul.classList.add('align-right');
          } else {
            ul.classList.remove('align-right')
          }
        }
      })
    })
  }
}

function createOffcanvasMenu() {
  new Mmenu("#menu", {
      offCanvas: {
        use: true,
      },
      theme: 'light',
      navbar: {
        title: 'Berlin Dance Festival'
      },
      slidingSubmenus: true,
    },
    {
      language: 'de',
      offCanvas: {
        clone: true
      }
    }
  );
}

function klaroPrivacySettingsIntegration(selectorButton, modal) {
  const button = document.querySelector(selectorButton);
  if (!button) {
    return;
  }

  button.addEventListener('click', function (event) {
    klaro.show(undefined, modal);
    event.preventDefault();
  });
}

document.addEventListener(
  'DOMContentLoaded', () => {
    alignMegaMenus();
    createOffcanvasMenu();
    klaroPrivacySettingsIntegration('#show-privacy-settings', true);
  }
);

window.addEventListener('scroll', () => {
  const header = document.querySelector('#header') || document.body;
  const isShrinked = header.classList.contains('shrinked');
  const shrinkAt = 200;
  const shrinkBy = 15;

  const distanceY = window.scrollY || 0,
    shrinkOn = isShrinked ? shrinkAt - shrinkBy : shrinkAt;

  if (distanceY > shrinkOn) {
    if (!isShrinked) {
      document.body.classList.add('shrinked-header');
      header.classList.add('shrinked');
    }
  } else {
    if (isShrinked) {
      document.body.classList.remove('shrinked-header');
      header.classList.remove('shrinked');
    }
  }
}, {passive: true});
